import './App.css';

import yco_logo from './yco-logo.png';
import csc_logo from './csc_logo.png';
import csc_page_title from './csc_page_title.png';


function App() {
  return (
    <div className="App">
      <div className="App-header">
		<p>
		  <img src={csc_logo} height="100" alt="Colligenda Systems"/>
		  <img src={csc_page_title} height="100" alt="Colligenda Systems"/>
		  <img src={csc_logo} height="100" alt="Colligenda Systems"/>
		</p>
      </div>
      <div className="App-overview">
	    <p>
		  We bring together extensive experience,
		  both as a collector and with many years in IT,
		  to bring specialised software as a service and bespoke solutions to collectors around the globe,<br/>
		  whatever their collecting interests may be.
		</p>
	  </div>
      <div className="App-dict-defn">
	    <p>
		  <b>colligenda</b> - Latin, neuter plural gerundive of <i>colligo</i>:<br/>
		  1. "those things that are to be collected"<br/>
		  <font size="3">
			<a href="https://en.wiktionary.org/wiki/colligendus#Latin">Wiktionary</a>
		  </font>
		</p>
	  </div>
	  <div className="App-yco">
	    <p>
		  We are excited to announce our latest online app<br/>
		  <a href="https://www.yourcollections.online">
		    <b>Your Collections Online</b>
		  </a><br/>
		  <a href="https://www.yourcollections.online">
		    <img src={yco_logo} height="100" alt="Your Collections Online"/>
		  </a><br/>
		  Try now with a <b>free 7 day trial</b>
		</p>
	  </div>
	  <div className="App-copyright">
	    <p>
		  &#169; Colligenda Systems Pty Ltd 2024
		</p>
	  </div>
	</div>
  );
}

export default App;
